/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.mat-step-label-active {
    color: #005dab !important;
}

.mat-step-label-selected {
    color: #005dab !important;
    font-size: x-large !important;
}